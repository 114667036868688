import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    
    path: '/',
    name: 'xz',
    component: () => import('../views/xz.vue'),
    meta:{
      title: '绵阳市行政一体化平台'
    }
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    // @ts-ignore
    document.title = to.meta.title
  }
  next()//执行进入路由，如果不写就不会进入目标页
})


export default router
